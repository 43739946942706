import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "./CustomButton";
import { Close } from "@mui/icons-material";
import CustomIconButton from "./CustomIconButton";
import NullCheck from "./NullCheck";

const CustomDialog = ({ open, onClose, children, title, actionBtn, extraBtn1 }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "80%",
          maxHeight: 600,
        },
        "& .MuiBackdrop-root": {
          bgcolor: "rgba(0 , 0, 0, 0.1)",
          backdropFilter: "blur(2px)",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">{t(title)}</Typography>

          <CustomIconButton size={30} icon={<Close />} onClick={onClose} />
        </Stack>
      </DialogTitle>
      {/* <DialogTitle>{t(title)}</DialogTitle> */}
      <DialogContent dividers>{children}</DialogContent>

      <DialogActions sx={{ gap: 2 }}>
        
      <NullCheck valueForCheck={extraBtn1}>
          <CustomButton
            title={t(extraBtn1?.title)}
            color={extraBtn1?.color ?? "primary"}
            onClick={() => extraBtn1?.onClick()}
          />
        </NullCheck>

        <NullCheck valueForCheck={actionBtn}>
          <CustomButton
            title={t(actionBtn?.title)}
            autoFocus
            color={actionBtn?.color ?? "primary"}
            onClick={() => actionBtn?.onClick()}
          />
        </NullCheck>
      </DialogActions>
    </Dialog>
  );
};
export default CustomDialog;
