import { configureStore } from "@reduxjs/toolkit";
import userSettingsSlice from "./userSettingsSlice";
import sysSlice from "./sysSlice";
import bizSlice from "./bizSlice";
import calendarSlice from "./plugins/calendar";
import servicesCallSlice from "./servicesCallSlice";
import clientsSlice from "./clientsSlice";
import pwaSlice from "./pwaSlice";

const rootReducer = configureStore({
  reducer: {
    userSettings: userSettingsSlice,
    sys: sysSlice,
    biz: bizSlice,
    pwa: pwaSlice,
    servicesCall: servicesCallSlice,
    calendar: calendarSlice,
    clients:clientsSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default rootReducer;
