import { useDispatch, useSelector } from "react-redux";
import CustomPage from "../../../components/CustomPage";
import { useEffect, useState } from "react";
import DatesReports from "../../../components/CustomSearch";
import { ST_TYPE_ID } from "../const";
import moment from "moment";
import Header from "../Header";
import NullCheck from "../../../components/NullCheck";
import { Divider, Stack, Typography } from "@mui/material";
import http from "../../../http";
import { DataGrid } from "@mui/x-data-grid";
import { DATE_FOR_API_FORMAT, DATE_TIME_FORMAT } from "../../../config/const";
import { useTranslation } from "react-i18next";
import { apiUrl } from "../../../config/settings";
import { setShowLoading } from "../../../store/sysSlice";
import {
  LocalAtmOutlined,
  LockClockOutlined,
  LockOpenOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { currencyFormat, sum } from "../../../config/functions";

const ServicesCallReport = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const clientsList = useSelector((state) => state?.clients?.list);
  const [datesReports, setDatesReports] = useState(true);
  const [list, setList] = useState([]);
  const [openServices, setOpenServices] = useState([]);
  const [closeServices, setCloseServices] = useState([]);
  const [waitingServices, setWaitingServices] = useState([]);
  const [revenues, setRevenues] = useState(0);
  const [employee, setEmployee] = useState(null);

  useEffect(() => {
    if (list) {
      setOpenServices(
        list.filter((service) => service.stTypeId === ST_TYPE_ID.OPEN)
      );
      setCloseServices(
        list.filter((service) => service.stTypeId === ST_TYPE_ID.CLOSE)
      );
      setWaitingServices(
        list.filter((service) => service.stTypeId === ST_TYPE_ID.WAITING)
      );

      let revenuesPrice = 0;

      revenuesPrice = list.filter(
        (service) => service.stTypeId === ST_TYPE_ID.CLOSE
      );

      revenuesPrice = sum(revenuesPrice, "price");
      setRevenues(revenuesPrice);
    }
  }, [list]);

  const callBackDate = async (data) => {
    if (data) {
      dispatch(setShowLoading(true));
      const dates = {
        start: moment(data?.startDate).format(DATE_FOR_API_FORMAT),
        end: moment(data?.endDate).format(DATE_FOR_API_FORMAT),
      };

      http
        .get(`${apiUrl}/reports/servicesCall/${dates?.start}/${dates?.end}`)
        .then((res) => {
          const list = res?.data?.data;
          dispatch(setShowLoading(false));

          if (data.selectedEmployee) {
            const findEmp = list?.find(
              (emp) => emp.id === data.selectedEmployee
            );

            setEmployee(findEmp ? findEmp : null);
          }

          let newList = list?.filter((service) =>
            moment(service.created).isBetween(data?.startDate, data.endDate)
          );

          if (data.selectedEmployee !== "all")
            newList = newList?.filter(
              (service) => service.employee === data.selectedEmployee
            );

          if (data.selectedClients !== "all")
            newList = newList?.filter(
              (service) =>
                parseInt(service.clientId) === parseInt(data.selectedClients)
            );

          if (data.selectedStatus !== "all")
            newList = newList?.filter(
              (service) => service.stTypeId === data.selectedStatus
            );

          if (data.selectedPmtType !== "all")
            newList = newList?.filter(
              (service) => service.pmtTypeId === data.selectedPmtType
            );

          if (data.selectedInvoiceStatus !== "all") {
            if (data.selectedInvoiceStatus === "full") {
              newList = newList?.filter((service) => service.invoiceNum);
            } else {
              newList = newList?.filter((service) => !service.invoiceNum);
            }
          }

          setList(newList);
        });
    }
  };

  const servicesBystType = [
    {
      id: 1,
      icon: <LockOpenOutlined sx={{ fontSize: 50 }} />,
      value: openServices?.length,
      label: "קריאות פתוחות",
    },
    {
      id: 2,
      icon: <LockOutlined sx={{ fontSize: 50 }} />,
      value: closeServices?.length,
      label: "קריאות סגורות",
    },
    {
      id: 3,
      icon: <LockClockOutlined sx={{ fontSize: 50 }} />,
      value: waitingServices?.length,
      label: "קריאות בהמתנה",
    },
    {
      id: 4,
      icon: <LocalAtmOutlined sx={{ fontSize: 50 }} />,
      value: `${currencyFormat(revenues)}`,
      label: "הכנסות",
    },
  ];

  return (
    <CustomPage header={<Header setDatesReports={setDatesReports} list={list} />}>
      <NullCheck valueForCheck={list.length > 0}>
        <Stack spacing={2}>
          <Typography variant="h4">
            {employee
              ? `${t("EMPLOYEE")}: ${employee?.fn} ${employee?.ln}`
              : ""}
          </Typography>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "start" }}
            alignItems={{ xs: "center", md: "start" }}
            gap={2}
          >
            {servicesBystType?.map((service) => {
              return (
                <Stack
                  key={service?.id}
                  width="100%"
                  direction="row"
                  alignItems="center"
                  padding={2}
                  border="1px solid black"
                  gap={2}
                >
                  {service?.icon}
                  <Divider orientation="vertical" flexItem />
                  <Stack>
                    <Typography variant="h6">{service.label}</Typography>
                    <Typography variant="h4">{service.value}</Typography>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>

          <DataGrid
            hideFooter
            autoHeight
            disableRowSelectionOnClick
            rowReordering
            disableColumnMenu
            columns={[
              {
                field: "callId",
                headerName: t("CALL_ID"),
                width: 150,

                filterable: false,
              },
              {
                field: "clientCallNumner",
                headerName: t("CLIENT_CALL_NUMNER"),
                width: 150,

                filterable: false,
              },
              {
                field: "name",
                headerName: t("CONTACT_NAME"),
                width: 150,

                filterable: false,
              },
              {
                field: "tel",
                headerName: t("TEL"),
                width: 150,

                filterable: false,
              },
              {
                field: "created",
                headerName: t("CREATED"),
                width: 200,

                filterable: false,
                renderCell: (value) => {
                  return moment(value?.row.created).format(DATE_TIME_FORMAT);
                },
              },
            ]}
            rows={list?.map((call) => {
              return {
                ...call,
                id: call?._id,
                callId: call?.callId,
                clientCallNumner: call?.clientCallNumner,
                name: call?.name,
                tel: call?.tel,
                created: call?.created,
                content: call?.content,
              };
            })}
          />
        </Stack>
      </NullCheck>

      <DatesReports
        btnTitle="CREATE_REPORT"
        title='DATES'
        clientsList={clientsList}
        open={datesReports}
        setOpen={setDatesReports}
        callBack={callBackDate}
      />
    </CustomPage>
  );
};
export default ServicesCallReport;
