import Header from "./Header";
import { useSelector } from "react-redux";
import Menu from "./menu";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import ShowMassage from "components/ShowMassage";
import DeleteMassage from "components/DeleteMassage";
import {
  Box,
  Fade,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CreateServices from "containers/servicesCall/create";
import Details from "containers/servicesCall/components/details";

const Layout = ({ children }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const { servicesCallList } = useCheckData();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isLogin = useSelector((state) => state?.userSettings?.isLogin);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (!isMobile) setOpenMenu(true);
  }, []);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     servicesCallList();
  //   }, 600000);

  //   return () => clearInterval(timer);
  // }, []);

  useEffect(() => {
    // const homePage = location?.pathname === "/";
    const locPage =
      location?.pathname.includes("signup") ||
      location?.pathname.includes("Login") ||
      location?.pathname.includes("loginEmp");

    if (!isLogin && !locPage) {
      navigate("/Login");
    }
  }, [isLogin]);

  return (
    <Stack height={{ xs: "100svh", md: "100vh" }} bgcolor="bg.main">
      {isLogin ? (
        <Fade in={isLogin}>
          <Box sx={{ display: "flex", overflow: "hidden", height: "100%" }}>
            <Header setOpenMenu={setOpenMenu} />
            <Menu setOpenMenu={setOpenMenu} openMenu={openMenu} />

            <Stack
              height="100%"
              sx={{ overflowY: "scroll", scrollbarWidth: "none" }}
              component="main"
              flexGrow={1}
              padding={2}
              // padding={{xs:1, md:2}}
            >
              <Toolbar />
              {children}
            </Stack>
          </Box>
        </Fade>
      ) : (
        children
      )}

      <Loading />
      <DeleteMassage />
      <ShowMassage />
      <CreateServices />
      <Details />
    </Stack>
  );
};
export default Layout;
