import { createSlice } from "@reduxjs/toolkit";

const servicesCallSlice = createSlice({
  name: "servicesCall",
  initialState: {
    list: [],
    openCreateServices: false,
    servicesCallDetails: null,
  },
  reducers: {
    setList(state, action) {
      state.list = action.payload;
    },
    setOpenCreateServices(state, action) {
      state.openCreateServices = action.payload;
    },
    setServicesCallDetails(state, action) {
      state.servicesCallDetails = action.payload;
    },
  },
});

export const {setList, setOpenCreateServices, setServicesCallDetails} = servicesCallSlice.actions;
export default servicesCallSlice.reducer;
